import React from 'react';
import PropTypes from 'prop-types';

import moment from 'infra/moment';
import {humanDayOfWeek} from 'infra/formatters/time';

const Time = ({fulfillmentOffer, tzid}) => {
  const {startAt, endAt} = fulfillmentOffer;
  const shoppingDay = `${humanDayOfWeek(startAt, tzid)} ${moment.tz(startAt, tzid).format('M/D')}`;
  const timeRange = `${moment.tz(startAt, tzid).format('h:mma')}-${moment
    .tz(endAt, tzid)
    .format('h:mma')}`;
  return (
    <p className="time">
      {shoppingDay}, {timeRange}
    </p>
  );
};

Time.propTypes = {
  fulfillmentOffer: PropTypes.shape({
    endAt: PropTypes.string.isRequired,
    startAt: PropTypes.string.isRequired,
  }),
  tzid: PropTypes.string,
};

const Address = ({deliveryDetails, isAttendedDeliveryRequired}) => (
  <div className="review-order-page__address">
    <p className="address">
      {deliveryDetails.isGift ? (
        <span>
          {deliveryDetails.recipientName}
          <br />
        </span>
      ) : null}
      {deliveryDetails.address}
      <br />
      {deliveryDetails.address2 && deliveryDetails.address2.length ? (
        <span>
          {deliveryDetails.address2}
          <br />
        </span>
      ) : null}
      {`${deliveryDetails.city}, ${deliveryDetails.state} ${deliveryDetails.zip}`}
    </p>
    {isAttendedDeliveryRequired ? (
      <div className="alcohol-notification">
        <i className="icon icon-circle-info" />
        ID required in person for alcohol delivery
      </div>
    ) : (
      <div className="can-leave-at-door">
        <i className="icon icon-circle-checkmark" />
        {deliveryDetails.canLeaveAtDoor
          ? "It's ok to leave the order unattended"
          : "Don't leave the order unattended"}
      </div>
    )}
    <br />
    Delivery instructions: {deliveryDetails.deliveryInstructions || 'None'}
  </div>
);

Address.propTypes = {
  deliveryDetails: PropTypes.shape({
    address2: PropTypes.string,
    address: PropTypes.string,
    canLeaveAtDoor: PropTypes.bool,
    city: PropTypes.string,
    deliveryInstructions: PropTypes.string,
    recipientName: PropTypes.string,
    isGift: PropTypes.bool,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  isAttendedDeliveryRequired: PropTypes.bool,
};

export default function FulfillmentDetails({
  fulfillmentOffer,
  deliveryDetails,
  isAttendedDeliveryRequired,
  tzid,
}) {
  return (
    <div className="fulfillment-details summary-details raised-tile">
      <div className="raised-tile__header">
        {deliveryDetails.isGift ? 'Gift ' : ''}Delivery Time
        <a
          className="change js-change-time raised-tile__header-link"
          href="/basket/delivery_options"
        >
          change
        </a>
      </div>
      <div className="raised-tile__body">
        <Time fulfillmentOffer={fulfillmentOffer} tzid={tzid} />
      </div>
      <div className="raised-tile__header">
        {deliveryDetails.isGift ? 'Gift ' : ''}Delivery Address
        <a
          className="change js-change-address raised-tile__header-link"
          href="/basket/address_change"
        >
          change
        </a>
      </div>
      <div className="raised-tile__body">
        <Address
          deliveryDetails={deliveryDetails}
          isAttendedDeliveryRequired={isAttendedDeliveryRequired}
        />
      </div>
    </div>
  );
}

FulfillmentDetails.propTypes = {
  fulfillmentOffer: Time.propTypes.fulfillmentOffer,
  deliveryDetails: Address.propTypes.deliveryDetails,
  tzid: PropTypes.string,
  isAttendedDeliveryRequired: PropTypes.bool,
};
