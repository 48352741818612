import React, {
  useState,
  createContext,
  PropsWithChildren,
  FocusEvent,
  ChangeEvent,
  useMemo,
} from 'react';
import Cents from 'goodeggs-money';

import {DisplayTotal} from 'web/basket/basket_page';
import useClientSettings from 'web/hooks/useClientSettings';

interface Props {
  displayTotals: DisplayTotal[];
  onChange: (tipInCents: Cents, tipPercentage: number | null) => void;
  label?: string;
}

export interface TipContextState {
  availableOptions: Array<string | number>;
  disabled: boolean;
  customTip: string;
  selectedTipIndex: number | undefined;
  minTipDollars: number;
  showTipModal: boolean;
  subtotal: number;
  tipDisplayTotal?: DisplayTotal;
  label: string;
  handleSelectOption: (index: number, tipPercentage: number | null) => void;
  handleDisableLogic: () => void;
  setIsTipSelected: (show: boolean) => void;
  isTipSelected: boolean;
  onFocus: () => void;
  setShowTipModal: (show: boolean) => void;
  handleCustomInputChange: (
    e: FocusEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>,
  ) => void;
}

const defaultValues: TipContextState = {
  availableOptions: [],
  disabled: false,
  showTipModal: false,
  customTip: '0.00',
  minTipDollars: 0.5,
  selectedTipIndex: 1,
  subtotal: 0,
  tipDisplayTotal: undefined,
  label: 'Your tip',
  handleSelectOption: () => false,
  handleDisableLogic: () => {},
  setIsTipSelected: () => {},
  isTipSelected: false,
  onFocus: () => {},
  setShowTipModal: () => {},
  handleCustomInputChange: () => {},
};

export const TipContext = createContext<TipContextState>(defaultValues);

const formatMoney = (amount: Cents | number): string => {
  return new Cents(amount).toDollars().toFixed(2);
};

export const TippingContextProvider = ({
  children,
  ...props
}: PropsWithChildren<Props>): JSX.Element => {
  const clientSettings = useClientSettings();
  const MIN_TIP_VALUE = clientSettings.minTip;
  const minTipDollars = useMemo(() => {
    return new Cents(MIN_TIP_VALUE).toDollars();
  }, [MIN_TIP_VALUE]);
  const availableOptions = [
    ...clientSettings.availableTipPercentages.map(({percentage}) => percentage),
    'Custom',
  ];

  const tipDisplayTotal = props.displayTotals?.find((value) => value.key === 'tipFee');
  const isZeroTip = tipDisplayTotal?.amount === 0;
  const customSelectedAmount =
    tipDisplayTotal?.amount != null ? formatMoney(tipDisplayTotal.amount) : defaultValues.customTip;
  const [selectedTipIndex, setSelectedselectedTipIndex] = useState<number | undefined>(undefined);
  const [customTip, setCustomTip] = useState(
    !isZeroTip ? customSelectedAmount : defaultValues.customTip,
  );

  const [isTipSelected, setIsTipSelected] = useState(false);

  const [disabled, setDisabled] = useState(defaultValues.disabled);

  const [showTipModal, setShowTipModal] = useState(false);
  const subtotal = props.displayTotals.find((value) => value.key === 'subtotal')?.amount ?? 0;

  const handleSelectOption = (index: number, tipPercentage: number | null): void => {
    setSelectedselectedTipIndex(index);

    let isTipSelectState = false;
    if (index === availableOptions.length - 1 && customTip !== '0.00') {
      isTipSelectState = true;
    } else if (index !== undefined && index >= 0 && index < availableOptions.length - 1) {
      isTipSelectState = true;
    } else {
      isTipSelectState = false;
    }

    let amount = new Cents(0);
    if (tipPercentage != null) {
      amount = new Cents(subtotal).percent(tipPercentage);
    } else {
      setCustomTip(formatMoney(amount));
    }
    props.onChange(amount, tipPercentage ?? null);

    setIsTipSelected(isTipSelectState);
  };

  const sanitizeTipInput = (value: string): string => {
    // Remove any non-digit characters
    value = value.replace(/[^0-9]/g, '');

    // Remove leading zeros
    value = value.replace(/^0+/, '');

    // If the string is empty or zero, return "0.00"
    if (value === '') {
      return '0.00';
    }

    // Ensure the length is at least 3 by padding zeros at the start if necessary
    if (value.length < 3) {
      value = value.padStart(3, '0');
    }

    // Insert decimal point before the last two digits
    if (value.length < 7) {
      value = `${value.slice(0, -2)}.${value.slice(-2)}`;
    } else {
      value = `${value.slice(0, -3)}.${value.slice(-2)}`;
    }

    // Remove leading zeros again after decimal adjustment
    value = value.replace(/^0+/, '');

    // Ensure there's a zero before the decimal point if necessary
    if (value.startsWith('.')) {
      value = `0${value}`;
    }

    // Enforce maximum length of 7 characters including the decimal point
    if (value.length > 7) {
      value = value.substring(0, 7);
    }

    return value;
  };

  const handleCustomInputChange = (
    e: FocusEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>,
  ): void => {
    const sanitizedValue = sanitizeTipInput(e.target.value);
    setCustomTip(sanitizedValue);
    const customTipCents = Cents.fromDollars(Number(sanitizedValue));
    if (customTipCents.toNumber() < MIN_TIP_VALUE) {
      props.onChange(Cents.fromDollars(0), null);
      setIsTipSelected(false);
    } else {
      setIsTipSelected(true);
      props.onChange(customTipCents, null);
    }
  };

  const handleDisableLogic = (): void => {
    setSelectedselectedTipIndex(undefined);

    const zeroAmount = new Cents(0);
    setCustomTip(formatMoney(zeroAmount));
    props.onChange(zeroAmount, null);

    setDisabled(!disabled);
  };

  const onFocus = (): void => {
    if (customTip === formatMoney(new Cents(0))) {
      setCustomTip('');
    }
  };
  return (
    <TipContext.Provider
      value={{
        availableOptions,
        disabled,
        customTip,
        selectedTipIndex,
        handleSelectOption,
        isTipSelected,
        handleCustomInputChange,
        handleDisableLogic,
        setIsTipSelected,
        minTipDollars,
        showTipModal,
        subtotal,
        setShowTipModal,
        onFocus,
        tipDisplayTotal,
        label: props.label ?? defaultValues.label,
      }}
    >
      {children}
    </TipContext.Provider>
  );
};
