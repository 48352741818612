import _ from 'lodash';
import {reducer as formReducer} from 'redux-form';

import {postJson} from 'web/helpers/api_helpers';
import basketDuck from 'web/helpers/basket_duck';

import {postCheckout} from '../api_client';
import basketActions from '../../helpers/basket_duck/actions';

export const BASKET_CHANGE_DAY_START = 'BASKET_CHANGE_DAY_START';
export const BASKET_CHANGE_DAY_SUCCESS = 'BASKET_CHANGE_DAY_SUCCESS';
export const BASKET_CHANGE_FULFILLMENT_OFFER_SUCCESS = 'BASKET_CHANGE_FULFILLMENT_OFFER_SUCCESS';
export const BASKET_CHANGE_FULFILLMENT_OFFER_ERROR = 'BASKET_CHANGE_FULFILLMENT_OFFER_ERROR';
export const BASKET_CHANGE_ADD_TO_ORDER_ID_START = 'BASKET_CHANGE_ADD_TO_ORDER_ID_START';
export const BASKET_CHANGE_ADD_TO_ORDER_ID_FINISH = 'BASKET_CHANGE_ADD_TO_ORDER_ID_FINISH';
export const BASKET_UPDATED_MASQUERADE_OVERRIDES = 'BASKET_UPDATED_MASQUERADE_OVERRIDES';
export const BASKET_ADD_TO_ORDER_START = 'BASKET_ADD_TO_ORDER_START';
export const BASKET_ADD_TO_ORDER_ERROR = 'BASKET_ADD_TO_ORDER_ERROR';
export const BASKET_SET_ATTENDED_DELIVERY_REQUIRED = 'BASKET_SET_ATTENDED_DELIVERY_REQUIRED';
export const BASKET_SHOW_SUBSCRIPTION_MODAL = 'BASKET_SHOW_SUBSCRIPTION_MODAL';
export const BASKET_HIDE_SUBSCRIPTION_MODAL = 'BASKET_HIDE_SUBSCRIPTION_MODAL';

function postUpdateMasqueradeOverrides({
  ignoreMinimumOrder,
  dontSendConfirmation,
  ignoreCapacities,
}) {
  return postJson('/api/basket/update-masquerade-overrides', {
    body: {ignoreMinimumOrder, dontSendConfirmation, ignoreCapacities},
  });
}

function postFulfillmentOffer(fulfillmentOffer) {
  return postJson('/api/basket/update-fulfillment-offer', {
    body: {fulfillmentOffer},
  });
}

export const actions = {
  updateFulfillmentDay: (newDay) => (dispatch) => {
    // activate spinner
    dispatch({
      type: BASKET_CHANGE_DAY_START,
      currentFulfillmentDay: newDay,
    });
    return dispatch(basketDuck.actions.changeFulfillmentDay(newDay)).then(
      ({
        basket,
        addToOrderId,
        currentFulfillmentDay,
        fulfillmentOffers,
        products,
        sanitizationChanges,
        selectedFulfillmentOffer,
        isAttendedDeliveryRequired,
      }) =>
        dispatch({
          type: BASKET_CHANGE_DAY_SUCCESS,
          basket,
          addToOrderId,
          currentFulfillmentDay,
          fulfillmentOffers,
          products,
          sanitizationChanges,
          selectedFulfillmentOffer,
          isAttendedDeliveryRequired,
        }),
    );
  },

  showSubscriptionModal:
    ({showDiscountContent}) =>
    (dispatch) => {
      dispatch({
        type: BASKET_SHOW_SUBSCRIPTION_MODAL,
        showDiscountContent,
      });
    },

  hideSubscriptionModal: () => (dispatch) => {
    dispatch({
      type: BASKET_HIDE_SUBSCRIPTION_MODAL,
    });
  },

  updateFulfillmentOffer: (newFulfillmentOffer) => (dispatch) =>
    postFulfillmentOffer(newFulfillmentOffer)
      .then((fulfillmentOffer) =>
        dispatch({
          type: BASKET_CHANGE_FULFILLMENT_OFFER_SUCCESS,
          fulfillmentOffer,
        }),
      )
      .then(() => {
        dispatch(basketActions.updateTotals());
      })
      .catch((err) => {
        dispatch({
          type: BASKET_CHANGE_FULFILLMENT_OFFER_ERROR,
          error: {
            heading: err.customerMessage,
          },
        });
      }),

  updateAddToOrderId: (newAddToOrderId) => (dispatch) => {
    dispatch({
      type: BASKET_CHANGE_ADD_TO_ORDER_ID_START,
      addToOrderId: newAddToOrderId,
    });
    dispatch(basketDuck.actions.updateTotals()).then(() =>
      dispatch({
        type: BASKET_CHANGE_ADD_TO_ORDER_ID_FINISH,
      }),
    );
  },

  updateMasqueradeOverrides:
    (ignoreMinimumOrder, dontSendConfirmation, ignoreCapacities) => (dispatch) =>
      postUpdateMasqueradeOverrides({
        ignoreMinimumOrder,
        dontSendConfirmation,
        ignoreCapacities,
      }).then(({masqueradeOverrides, fulfillmentOffers}) =>
        dispatch({
          type: BASKET_UPDATED_MASQUERADE_OVERRIDES,
          masqueradeOverrides,
          fulfillmentOffers,
        }),
      ),

  addToOrder: () => (dispatch, getState) => {
    dispatch({type: BASKET_ADD_TO_ORDER_START});

    const {addToOrderId} = getState();

    return postCheckout({fulfillmentId: addToOrderId})
      .then(({checkoutId}) => {
        if (checkoutId) {
          window.location.href = `/refer-a-friend?checkoutId=${checkoutId}`;
        } else {
          dispatch({
            type: BASKET_ADD_TO_ORDER_ERROR,
            error: {
              type: 'UNKNOWN_CHECKOUT_ERROR',
              message: 'Internal Error - please contact Community Care',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: BASKET_ADD_TO_ORDER_ERROR, error});
      });
  },
};

const NEW_STATE = {
  isLoading: false,
};

export function reducer(state = NEW_STATE, action = {}) {
  let newState = _.assignIn({}, state);

  switch (action.type) {
    case BASKET_CHANGE_DAY_START:
      newState = _.assignIn(newState, {isLoading: true});
      break;
    case BASKET_CHANGE_DAY_SUCCESS:
      newState = _.assignIn(
        newState,
        _.pick(
          action,
          'basket',
          'currentFulfillmentDay',
          'addToOrderId',
          'sanitizationChanges',
          'selectedFulfillmentOffer',
          'fulfillmentOffers',
          'isAttendedDeliveryRequired',
        ),
        {isLoading: false},
      );
      newState.products = _.assignIn({}, newState.products, action.products);
      break;
    case BASKET_CHANGE_FULFILLMENT_OFFER_SUCCESS:
      newState = _.assignIn(newState, {selectedFulfillmentOffer: action.fulfillmentOffer});
      break;
    case BASKET_CHANGE_FULFILLMENT_OFFER_ERROR:
      newState = _.assignIn(newState, {error: action.error});
      break;
    case BASKET_CHANGE_ADD_TO_ORDER_ID_START:
      newState = _.assignIn(newState, {addToOrderId: action.addToOrderId}, {isLoading: true});
      break;
    case BASKET_CHANGE_ADD_TO_ORDER_ID_FINISH:
      newState = _.assignIn(newState, {isLoading: false});
      break;
    case BASKET_UPDATED_MASQUERADE_OVERRIDES:
      newState = _.assignIn(newState, _.pick(action, 'masqueradeOverrides', 'fulfillmentOffers'));
      break;
    case BASKET_ADD_TO_ORDER_START:
      newState = _.assignIn(newState, {checkoutError: null});
      break;
    case BASKET_ADD_TO_ORDER_ERROR:
      newState = _.assignIn(newState, {checkoutError: action.error});
      break;
    case BASKET_SHOW_SUBSCRIPTION_MODAL:
      newState = _.assignIn(newState, {
        showSubscriptionModal: true,
        showDiscountContent: action.showDiscountContent,
      });
      break;
    case BASKET_HIDE_SUBSCRIPTION_MODAL:
      newState = _.assignIn(newState, {showSubscriptionModal: false, showDiscountContent: false});
      break;
    case basketDuck.actions.SET_ATTENDED_DELIVERY_REQUIRED:
      newState = _.assignIn(newState, {isAttendedDeliveryRequired: action.required});
      break;
    default:
  }

  // Form reducer for masquerade overrides
  const form = formReducer(newState.form, action);
  newState = _.assignIn({}, newState, {form});

  // Derived field
  let isBelowMinimumForNewOrder;
  if (newState.isMasquerading && newState.masqueradeOverrides.ignoreMinimumOrder) {
    isBelowMinimumForNewOrder = false;
  } else if (newState.isEmployee) {
    isBelowMinimumForNewOrder = false;
  } else {
    isBelowMinimumForNewOrder = newState.basket.totals.newOrderAmountToMinimum > 0;
  }
  newState = _.assignIn({}, newState, {isBelowMinimumForNewOrder});
  return newState;
}
