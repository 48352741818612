import update from 'immutability-helper';
import {gql} from '@apollo/client';
import {debounce} from 'lodash';

import client from 'web/helpers/graphql_client';
import {humanPriceFromCents} from 'infra/formatters/money';

import {postCheckout} from '../api_client';

export const CHECKOUT_START = 'REVIEW_PAGE_CHECKOUT_START';
export const CHECKOUT_ERROR = 'REVIEW_PAGE_CHECKOUT_ERROR';
export const SET_TIP = 'REVIEW_PAGE_SET_TIP';
const UPDATE_TIP_MUTATION = gql`
  mutation UpdateTip($input: TipInput!) {
    updateTip(input: $input) {
      success
    }
  }
`;

const updateTipMutation = debounce(async (input) => {
  try {
    const {data} = await client.mutate({
      mutation: UPDATE_TIP_MUTATION,
      variables: {
        input,
      },
    });
    if (data.updateTip.errorInfo) throw new Error(data.updateTip.errorInfo);
    if (!data.updateTip.success)
      throw new Error('Operation was successful but success indicator was not set');
    return data;
  } catch (error) {
    console.error('Error updating tip', error);
  }
}, 500);

export const actions = {
  checkout: (user) => (dispatch) => {
    if (!user.lastName || !user.phone) {
      return (window.location.href = '/basket/address_change');
    }

    dispatch({type: CHECKOUT_START});
    postCheckout()
      .then(({checkoutId}) => {
        if (checkoutId) {
          window.location.href = `/refer-a-friend?checkoutId=${checkoutId}`;
        } else {
          dispatch({
            type: CHECKOUT_ERROR,
            error: {
              type: 'UNKNOWN_CHECKOUT_ERROR',
              message: 'Internal Error - please contact Community Care',
            },
          });
        }
      })
      .catch((error) => {
        if (['FULFILLMENT_OFFER_SOLD_OUT', 'FULFILLMENT_OFFER_CLOSED'].includes(error.type)) {
          window.location.href = `/basket/delivery_options?errorMessage=${error.message}`;
        } else {
          dispatch({type: CHECKOUT_ERROR, error});
        }
      });
  },
  setTip: (tipCents, tipPercentage) => async (dispatch) => {
    dispatch({type: SET_TIP, body: {tipCents, tipPercentage}});
    const input = {
      amount: tipCents.toNumber(),
      percentageAmount: tipPercentage ?? null,
    };
    await updateTipMutation(input);
  },
};

const initialState = {
  error: null,
  working: false,
  displayTotals: [],
};

export function reducer(state = initialState, action) {
  if (action.type === CHECKOUT_START) {
    return update(state, {
      error: {$set: null},
      working: {$set: true},
    });
  } else if (action.type === CHECKOUT_ERROR) {
    return update(state, {
      error: {$set: action.error},
      working: {$set: false},
    });
  } else if (action.type === SET_TIP && state.displayTotals.length > 0) {
    const currentTipFeeAmount =
      state.displayTotals.find((total) => total.key === 'tipFee')?.amount || 0;
    return {
      ...state,
      totalDue:
        state.displayTotals.find((total) => total.key === 'totalDue')?.amount +
        action.body.tipCents.toNumber() -
        currentTipFeeAmount,
      displayTotals: state.displayTotals.map((total) => {
        if (total.key === 'tipFee') {
          return {
            ...total,
            amount: action.body.tipCents.toNumber(),
            formattedAmount: humanPriceFromCents(action.body.tipCents.toNumber(), {short: false}),
            meta: {
              ...total.meta,
              percentage: action.body.tipPercentage,
            },
          };
        }
        if (total.key === 'totalDue') {
          return {
            ...total,
            amount: total.amount + action.body.tipCents.toNumber() - currentTipFeeAmount,
            formattedAmount: humanPriceFromCents(
              total.amount + action.body.tipCents.toNumber() - currentTipFeeAmount,
              {short: false},
            ),
          };
        }
        return total;
      }),
    };
  }
  return state;
}
