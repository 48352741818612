import update from 'immutability-helper';
import {Reducer, Dispatch} from 'redux';
import {reducer as formReducer, getFormValues, isValid} from 'redux-form';

import {reducer as creditCardFormReducer} from 'web/components/stripe_credit_card_form/duck';
import {
  actions as navigationActions,
  reducer as navigationReducer,
} from 'web/helpers/navigation_duck';

import {redeemGiftCard, applyPromoCode, removePromoCode} from './api_client';
import {StoreData} from './controller';

const CHANGE_CREDIT_CARD = 'CHANGE_CREDIT_CARD';
export const START_SAVING = 'PAYMENT_DETAILS_PAGE_START_SAVING';
const FINISH_SAVING_PROMO_CODE_WITH_ERROR = 'PAYMENT_DETAILS_PAGE_PROMO_CODE_FINISH_SAVING';

function isGiftCard(promoOrGiftCode: string): boolean {
  return /^\s*[0-9a-z]{3}-[0-9a-z]{3}-[0-9a-z]{3}\s*$/i.test(promoOrGiftCode);
}

export const actions = {
  changeCreditCard: () => (dispatch: Dispatch) => {
    dispatch({type: CHANGE_CREDIT_CARD});
  },
  startSaving: () => ({
    type: START_SAVING,
  }),
  submitPromoOrGiftCode: () => (dispatch: Dispatch, getState: () => StoreData) => {
    const {form} = getState();
    const formIsValid = isValid('promoOrGiftCode')({form});
    if (!formIsValid) {
      return;
    }
    const {promoCode: promoOrGiftCode} = getFormValues('promoOrGiftCode')({
      form,
    }) as {promoCode: string};
    const applyCode = isGiftCard(promoOrGiftCode) ? redeemGiftCard : applyPromoCode;
    dispatch(actions.startSaving());
    return applyCode(promoOrGiftCode)
      .then(() => dispatch(navigationActions.navigate('/basket/review')))
      .catch((e) => {
        const error = {
          type: e.type || 'UNKNOWN',
          message: e.customerMessage || e.message || 'Please call community care.',
        };
        dispatch({
          type: FINISH_SAVING_PROMO_CODE_WITH_ERROR,
          error,
        });
      });
  },
  dismissPromoCodes: () => async (dispatch: Dispatch) => {
    return removePromoCode()
      .then(() => dispatch(navigationActions.navigate('/basket/review')))
      .catch((e) => {
        const error = {
          type: e.type || 'UNKNOWN',
          message: e.customerMessage || e.message || 'Please call community care.',
        };
        dispatch({
          type: FINISH_SAVING_PROMO_CODE_WITH_ERROR,
          error,
        });
      });
  },
};

const initialState = {
  isSaving: false,
  promoError: null,
};

const paymentDetailsPageReducer: Reducer<StoreData['paymentDetailsPage']> = (state, action) => {
  state = state ?? initialState;
  switch (action.type) {
    case START_SAVING: {
      return update(state, {
        isSaving: {$set: true},
        promoError: {$set: null},
      });
    }
    case FINISH_SAVING_PROMO_CODE_WITH_ERROR: {
      return update(state, {
        isSaving: {$set: false},
        promoError: {$set: action.error},
      });
    }
  }
  return state;
};

export const reducer: Reducer<StoreData> = (state, action) => {
  // TODO: (@shermam) This is not ideal, but the state should always be set here
  // since we preload it from the controller. We should maybe come up with a better
  // way of asserting the type here
  if (!state) throw new Error('State should always be preloaded here');

  let updatedState = update(state, {
    form: {$set: formReducer(state.form, action)},
    paymentDetailsPage: {$set: paymentDetailsPageReducer(state.paymentDetailsPage, action)},
    creditCardForm: {$set: creditCardFormReducer(state.creditCardForm, action)},
    navigation: {$set: navigationReducer(state.navigation, action)},
  });

  if (action.type === CHANGE_CREDIT_CARD) {
    updatedState = update(updatedState, {
      isEditingCreditCard: {$set: true},
    });
  }
  if (state.isEditingCreditCard) {
    // always override editing state
    updatedState = update(updatedState, {creditCardForm: {isEditing: {$set: true}}});
  }

  return updatedState;
};
