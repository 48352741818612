import React from 'react';
import PropTypes from 'prop-types';
import {formatCreditCard} from 'goodeggs-formatters';

// TODO(lei): consolidate formatters
import {formatCents} from 'web/helpers/money';
import {formatPromoCode} from 'infra/formatters/money';
import {getText} from 'infra/shared_text';

export default function PaymentDetails({
  allowChange,
  availableStoreCredit,
  basketContainsPromoProducts,
  creditCard,
  promoCodes,
  referralGift,
  onRemovePromoCodes,
}) {
  const promoHref = '/basket/payment_details';
  const cardHref = `${promoHref}?newCard=true`;
  return (
    <div className="payment-details summary-details raised-tile">
      <div className="raised-tile__header">
        <span className="header">Payment</span>
        {allowChange ? (
          <a className="change raised-tile__header-link" href={cardHref}>
            change
          </a>
        ) : null}
      </div>
      <div className="raised-tile__body">
        <label>Current Card</label>
        <p className="credit-card">{formatCreditCard(creditCard)}</p>
        {availableStoreCredit ? (
          <p className="gift-credit">
            <label>Available Gift Credit</label>${formatCents(availableStoreCredit)}
          </p>
        ) : null}
        {promoCodes.length || referralGift ? (
          <span>
            <label>Promo Code</label>
            {promoCodes.length ? (
              promoCodes.map(function (promoCode, i) {
                return (
                  <p className="promo" key={`promo-${i}`}>
                    {formatPromoCode(promoCode)}
                    {i === promoCodes.length - 1 && (
                      <span onClick={onRemovePromoCodes} className="remove-promo">
                        {' '}
                        Remove{' '}
                      </span>
                    )}
                  </p>
                );
              })
            ) : (
              <p className="promo">Referral</p>
            )}
          </span>
        ) : null}
        {allowChange && !basketContainsPromoProducts ? (
          <a className="checkout__link have-promo-code" href={promoHref}>
            {getText('enterCode')}
          </a>
        ) : null}
      </div>
    </div>
  );
}

PaymentDetails.propTypes = {
  allowChange: PropTypes.bool,
  availableStoreCredit: PropTypes.number,
  basketContainsPromoProducts: PropTypes.bool,
  creditCard: PropTypes.object,
  promoCodes: PropTypes.array,
  referralGift: PropTypes.string,
  onRemovePromoCodes: PropTypes.func,
};
