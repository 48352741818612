import React from 'react';

import {humanPriceFromCents} from 'infra/formatters/money';
import useClientSettings from 'web/hooks/useClientSettings';
import StrikethroughLineItem from 'web/components/strikethrough_lineitem';
import {Item as BasketItem, LineItemTotals} from 'web/basket/basket_page';
import {UiMarketProduct} from 'web/helpers/serializers/product';

interface ItemSummaryProps {
  item: BasketItem;
  product: UiMarketProduct;
  subtotal: number;
  basetotal: number;
  subscriptionDiscount: number;
  subtotalMinusSubscriptionDiscount: number;
}

const ItemSummary: React.FC<ItemSummaryProps> = ({
  product,
  item,
  subtotal,
  basetotal,
  subscriptionDiscount,
  subtotalMinusSubscriptionDiscount,
}) => {
  const clientSettings = useClientSettings();
  const hasPromoPricing = subtotal < basetotal;
  const hasSubscriptionDiscount = subscriptionDiscount > 0;

  return (
    <div className="summary-item is-not-interactive clearfix" data-product-id={product.id}>
      <div className="summary-item__photo">
        <img src={product.photoUrl} />
      </div>

      <div className="summary-item__details">
        {product.hasVariableProducers ? null : (
          <div className="summary-item__producer producer">{product.currentProducer.name}</div>
        )}
        <div className="summary-item__name name">{product.name}</div>
        <div className="summary-item__unit unit">
          {item.quantity} x {product.retailUnits}
          {item.hasBottleDeposit ? '*' : null}
        </div>
        {item.shouldSubscribe ? (
          <div className="summary-item__subscription">
            <i className="icon icon-subscriptions" />
            Subscribed
          </div>
        ) : null}
      </div>

      {!hasPromoPricing && hasSubscriptionDiscount ? (
        <div className="summary-item__prices">
          <div className="summary-item__strikethrough-price">{humanPriceFromCents(subtotal)}</div>
          <div className="summary-item__price">
            {humanPriceFromCents(subtotalMinusSubscriptionDiscount)}
          </div>
        </div>
      ) : (
        <div className="summary-item__price">
          {humanPriceFromCents(subtotal, {showZeroAsFree: true})}
          {hasPromoPricing && clientSettings.strikethroughPricing.enableWeb && (
            <StrikethroughLineItem basetotal={basetotal} />
          )}
        </div>
      )}
    </div>
  );
};

interface ItemsSummaryProps {
  items: BasketItem[];
  productsById: Record<string, UiMarketProduct>;
  lineItemTotals: LineItemTotals;
}

const ItemsSummary: React.FC<ItemsSummaryProps> = ({items, lineItemTotals, productsById}) => {
  return (
    <div>
      {items.map((item) => {
        const product = productsById[item.product.id];
        return (
          <ItemSummary
            item={item}
            key={product.id}
            product={product}
            subtotal={lineItemTotals[item.id]?.subtotal ?? 0}
            basetotal={lineItemTotals[item.id]?.basetotal}
            subscriptionDiscount={lineItemTotals[item.id]?.subscriptionDiscount}
            subtotalMinusSubscriptionDiscount={
              lineItemTotals[item.id]?.subtotalMinusSubscriptionDiscount
            }
          />
        );
      })}
    </div>
  );
};

export default ItemsSummary;
