import React, {ForwardedRef, useContext, useEffect, useState} from 'react';
import classNames from 'classnames';

import ToggleInput from 'web/account/components/toggle_input';
import {InfoModal} from 'web/components/info_modal';
import Image from 'web/components/image';

import {TipContext} from './tipping_context';
import TipOption from './components/tip_option';

interface TippingSectionProps {
  handleTipSelectionCallback?: (tipSelected: boolean) => boolean;
  shakeTippingSection?: boolean;
}

const TippingSection = React.forwardRef(
  (
    {shakeTippingSection, handleTipSelectionCallback}: TippingSectionProps,
    ref: ForwardedRef<HTMLDivElement | null>,
  ): JSX.Element => {
    const {
      availableOptions,
      label,
      disabled,
      handleSelectOption,
      selectedTipIndex,
      subtotal,
      customTip,
      handleDisableLogic,
      onFocus,
      showTipModal,
      setShowTipModal,
      tipDisplayTotal,
      handleCustomInputChange,
      minTipDollars,
      isTipSelected,
      setIsTipSelected,
    } = useContext(TipContext);

    const [isTipRefused, setIstipRefused] = useState(false);

    useEffect(() => {
      if (handleTipSelectionCallback) handleTipSelectionCallback(isTipSelected);
    }, [isTipSelected, handleTipSelectionCallback]);

    return (
      <div ref={ref} id="tipping-section">
        {showTipModal && tipDisplayTotal?.info && (
          <InfoModal info={tipDisplayTotal.info} onClose={() => setShowTipModal(false)} />
        )}
        <div className="raised-tile">
          <div className="raised-tile__header tipping-header">
            Good Eggs Tip (Optional){' '}
            <div className="tipping_section__header">
              <span className="tipping_section__info-link" onClick={() => setShowTipModal(true)}>
                <Image
                  width={16}
                  height={16}
                  src="/img/web/questionmark_web.png"
                  alt="question mark - tipping"
                />
              </span>
            </div>
          </div>
          <div
            className={
              shakeTippingSection
                ? 'tip-body raised-tile__body tipping-warning-effect'
                : 'tip-body raised-tile__body'
            }
          >
            <label>{label}</label>
            <div className="tip-section">
              <div
                className={classNames({
                  'tip-container-enabled': !disabled,
                  'tip-container-disabled': Boolean(disabled),
                })}
              >
                {availableOptions.map((tipPercentage, tipIndex) => {
                  return (
                    <TipOption
                      key={`tip-percentage-${tipPercentage}`}
                      tipPercentage={tipPercentage}
                      total={subtotal}
                      isSelected={tipIndex === selectedTipIndex}
                      onClick={(selectedTipPercentage) => {
                        handleSelectOption(tipIndex, selectedTipPercentage);
                      }}
                      isDisabled={Boolean(disabled)}
                    />
                  );
                })}
              </div>
              {selectedTipIndex === availableOptions.length - 1 && !disabled && (
                <div className="input-container">
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      onFocus={onFocus}
                      value={customTip}
                      onChange={(e) => {
                        handleCustomInputChange(e);
                        if (handleTipSelectionCallback) handleTipSelectionCallback(isTipSelected);
                      }}
                      onBlur={handleCustomInputChange}
                      data-testid="custom-tip-input"
                    />
                  </div>
                  {Boolean(Number(customTip) && Number(customTip) < minTipDollars) && (
                    <p data-testid="error-min-message">
                      The minimum tip is {minTipDollars.toFixed(2)}.
                    </p>
                  )}
                </div>
              )}
              <ToggleInput
                title="I don't want to leave a tip."
                checked={isTipRefused}
                onToggle={() => {
                  handleDisableLogic();
                  setIstipRefused(!isTipRefused);
                  setIsTipSelected(!isTipRefused);
                }}
                dismissIcon
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

TippingSection.displayName = 'Tipping Section';

export default TippingSection;
