import React, {useMemo} from 'react';
import Cents from 'goodeggs-money';
import classNames from 'classnames';

import {humanPriceFromCents} from 'infra/formatters/money';

interface TipOptionProps {
  total: number;
  onClick: (tipPercentage: number | null) => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  tipPercentage: number | string;
}

const TipOption = ({
  total,
  isSelected,
  isDisabled,
  onClick,
  tipPercentage,
}: TipOptionProps): JSX.Element => {
  const tipLabel = typeof tipPercentage === 'number' ? `${tipPercentage}%` : tipPercentage;
  const tipAmount = useMemo(() => {
    return typeof tipPercentage === 'number'
      ? humanPriceFromCents(new Cents(total).percent(tipPercentage).toNumber())
      : null;
  }, [tipPercentage, total]);
  const handleChange = (): void => {
    if (isDisabled) return;
    if (typeof tipPercentage === 'number') {
      onClick(tipPercentage);
    } else {
      onClick(null);
    }
  };
  return (
    <div
      data-testid="tip-option"
      onClick={handleChange}
      className={classNames('tip-option', {
        'tip-option-selected': isSelected && !isDisabled,
        'tip-option-disabled': isDisabled,
      })}
    >
      {tipLabel}
      {tipAmount && <span>{tipAmount}</span>}
    </div>
  );
};

export default TipOption;
